import {State} from "@store/state";
import {createSelector} from "@ngrx/store";
import {NotificationListState} from "./notification-list.reducer";

const getNotificationListState = (state: State) => state.notificationList;

export const getNotifications = createSelector(
  getNotificationListState,
  (state: NotificationListState) => state
);

export const getNotificationTotalPages = createSelector(
  getNotificationListState,
  (state: NotificationListState) => state.notificationPagination.totalPages
);

export const getNotificationTotalNotifications = createSelector(
  getNotificationListState,
  (state: NotificationListState) => state.notificationPagination.totalNotifications
);

export const getNotificationList = createSelector(
  getNotificationListState,
  (state: NotificationListState) => state.notification.data
);

export const isNotificationLoading = createSelector(
  getNotificationListState,
  (state: NotificationListState) => state.notification.isLoading
);

export const isNotificationError = createSelector(
  getNotificationListState,
  (state: NotificationListState) => state.notification.error
)

export const getNotificationListPageNumber = createSelector(
  getNotificationListState,
  (state: NotificationListState) => state.notificationPagination.currentPageNumber
);

export const getNotificationListFilters = createSelector(
  getNotificationListState,
  (state: NotificationListState) => state.notification.filters
);

export const getPageSize = createSelector(
  getNotificationListState,
  (state: NotificationListState) => state.notificationPagination.pageSize
);

export const hasPageBeenReloaded = createSelector(
  getNotificationListState,
  (state: NotificationListState) => state.reload
);
