import {createAction, props} from "@ngrx/store";
import {FiltersDto} from "@dto/filters.dto";
import {NotificationListDto} from "@dto/notification-list.dto";
import {WebsocketUpdateDetailDto} from "@dto/websocket-update-detail.dto";

const state = "[Notification List]";

export const initNotification = createAction(
  `${state}[Init Notifications]`,
  props<{ allowedProductIdList: string[] }>()
);

export const loadNotificationPage = createAction(
  `${state}[Load Notification Page]`,
  props<{ currentPageNumber: number, filters: FiltersDto, pageSize: number, reload: boolean }>()
);

export const loadNotificationPageSuccess = createAction(
  `${state}[Load Notification Page] Success`,
  props<{ notificationList: NotificationListDto, reload: boolean }>()
);

export const loadNotificationPageFailure = createAction(
  `${state}[Load Notification Page] Failure`,
  props<{ error: string }>()
);

export const updateNotificationFromWs = createAction(
  `${state}[Update Notification]`,
  props<{ toUpdate: WebsocketUpdateDetailDto }>()
);
